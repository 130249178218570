
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
import Simple from '@/pages/wallet/keysAndPermissions/tabs/Simple.vue'
import Advance from '@/pages/wallet/keysAndPermissions/tabs/Advance.vue'
import Keys from '@/pages/account/accountTab/overview/components/overviewTabs/tabs/keys/Keys.vue'
//services
import AccountService from "@/services/AccountServices";
import MatomoService from "@/services/matomo.service";
//models
import AccountModel from "@/models/AccountModel";

export default defineComponent({
  components:{
    Loading,
    Simple,
    Advance,
    Keys
  },

  data(){
    const toast = useToast();
    let account!:AccountModel
    return{
      tabs:[
        {title:"Simple" , value:"simple"},
        {title:"Advance" , value:"advance"},
        // {title:"Link Auth" , value:"linkAuth"},
      ],
      loading:true,
      toast,
      selectedTab:'',
      account
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        await this.getAccount()
      }catch(e){}
      this.loading=false;
    },

    async getAccount(){
      let ac = (await AccountService.getSelectedAccount())
      let accountName = ac.data.accountName
      this.account = await AccountService.getAccount(accountName)
    },

    setQuery(){
      let query = this.$route.query
      const setQuery = { ...this.$route.query };
      if(query.tab){
        this.selectedTab = query.tab as string
        setQuery['tab']=this.selectedTab    
      }
      else{
        this.selectedTab = 'simple'
        setQuery['tab']='simple'
      }
      this.$router.push({ name: "permissions", query: setQuery })
    },

    disableScroll() {            
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function() {
          window.scrollTo(scrollLeft, scrollTop);
      }
    },
    enableScroll() {
      window.onscroll = function(){};
    }
  },

  created(){
    this.setQuery()
  },

  mounted(){
    this.init()
    MatomoService.trackPageView("View stake cpu/net page in wallet.");
  },

  watch:{
    selectedTab(){
      MatomoService.trackEvent(`Change permissions(wallet) to ${this.selectedTab}`,"change tab");
      const setQuery = { ...this.$route.query };
      setQuery['tab'] = this.selectedTab
      this.$router.push({ name: "permissions", query:setQuery})
      this.disableScroll()
      setTimeout(() => {
        this.enableScroll()
      } , 300)
    }
  }
    
})
