
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
//services
//models
import {RequiredAuthWaits , RequiredAuthWait} from "@/models/AccountModel"

export default defineComponent({
  props:{
    waits:RequiredAuthWaits
  },

  components:{
    Loading
  },

  data(){
    const toast = useToast();
    let waitList:RequiredAuthWait[] = []
    return{
      loading:true,
      toast,
      waitList
    }
  },
  
  methods:{
    init(){
      let waits = JSON.stringify(this.waits?.waits)
      waits = JSON.parse(waits)
      for(let wait of waits){
        this.waitList.push(new RequiredAuthWait(wait))
      }
    },
    addKey(){
      this.waitList.push(new RequiredAuthWait({wait_sec:0 , weight:1}))
    },
    remove(index:number){
      this.waitList.splice(index , 1)
    },

    sendWaits(){
      let waits = JSON.stringify(this.waitList)
      waits = JSON.parse(waits)
      this.$emit("setWaits" , waits)
    }
  },

  created(){
    this.init()
  },

  watch:{
    waitList:{
      handler(){
        this.sendWaits()
      },
      deep:true
    }
  }

    
})
