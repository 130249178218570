
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
//services
//models
import {RequiredAuthKeys , RequiredAuthKey} from "@/models/AccountModel"
export default defineComponent({
  props:{
    keys:RequiredAuthKeys
  },

  components:{
    Loading
  },

  data(){
    const toast = useToast();
    let keyList:RequiredAuthKey[] = []
    let test:any[] = []
    return{
      loading:true,
      toast,
      keyList,
      test,
    }
  },
  
  methods:{
    init(){
      let keys = JSON.stringify(this.keys?.keys)
      keys = JSON.parse(keys)
      for(let key of keys){
        this.keyList.push(new RequiredAuthKey(key))
      }
    },
    addKey(){
      this.keyList.push(new RequiredAuthKey({weight:1 , key:''}))
      this.test.push("hello" as string)
    },
    remove(index:number){
      this.keyList.splice(index , 1)
    },

    sendKeys(){
      let keys = JSON.stringify(this.keyList)
      keys = JSON.parse(keys)
      this.$emit("setKeys" , keys)
    }
  },

  created(){
    this.init()
  },

  watch:{
    keyList:{
      handler(){
        this.sendKeys()
      },
      deep: true
    }
  }
})
