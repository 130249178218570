
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
//services
import AccountService from "@/services/AccountServices";
//model
import AccountModel from '@/models/AccountModel'

export default defineComponent({
  components:{
    Loading
  },

  props:{
    account:AccountModel,
  },

  data(){
    const toast = useToast();
    return{
      loading:true,
      toast,
      activeKey:'',
      ownerKey:'',
      permission:''
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        await this.getAccount()
      }catch(e){}
      this.loading=false;
    },

    async getAccount(){
      let ac = await AccountService.getSelectedAccount()      
      this.permission = ac.data.permission
    },

    async changePemission(){
      let active , owner
      this.activeKey.trim() ? active = true : active = false
      this.ownerKey.trim() ? owner = true : owner = false
      try{
        await AccountService.simpleChangePermision(
          this.account?.account_name as string,
          this.permission,
          active,
          owner,
          this.activeKey.trim(),
          this.ownerKey.trim(),
        )
        this.toast.success(`Transaction was successful.`) ;
      }catch(e){
        this.toast.error(`${e}`) ;
      }
    }
  },

  mounted(){
    this.init()
  },

    
})
