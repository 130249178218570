
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
//services
//models
import {RequiredAuthAccounts , RequiredAuthAccount , RequiredAuthPerm} from "@/models/AccountModel"
export default defineComponent({
  props:{
    accounts:RequiredAuthAccounts
  },

  components:{
    Loading
  },

  data(){
    const toast = useToast();
    let accountList:RequiredAuthAccount[] = []
    return{
      loading:true,
      toast,
      accountList,
    }
  },
  
  methods:{
    init(){
      let accounts = JSON.stringify(this.accounts?.accounts)
      accounts = JSON.parse(accounts)
      for(let ac of accounts){
        this.accountList.push(new RequiredAuthAccount(ac))
      }
    },

    addAccount(){
      this.accountList.push(new RequiredAuthAccount({
          weight:1 ,
          permission: new RequiredAuthPerm({actor:'' , permission:''})
        })
      )
    },
    remove(index:number){
      this.accountList.splice(index , 1)
    },

    sendAccounts(){
      let accounts = JSON.stringify(this.accountList)
      accounts= JSON.parse(accounts)
      this.$emit("setAccounts" , accounts)
    }
  },

  created(){
    this.init()
  },

  watch:{
    accountList:{
      handler(){
        this.sendAccounts()
      },
      deep:true
    }
  }


    
})
