
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
import AdvanceItem from '@/pages/wallet/keysAndPermissions/tabs/components/AdvanceItem.vue'
//services
import AccountService from "@/services/AccountServices";
//model
import AccountModel from '@/models/AccountModel'
export default defineComponent({
  props:{
    account:AccountModel,
  },

  components:{
    Loading,
    AdvanceItem
  },

  data(){
    const toast = useToast();
    return{
      loading:true,
      toast,
      activeKey:'',
      accountPermission:''
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        this.getAccount()
      }catch(e){}
      this.loading=false;
    },
    async getAccount(){
      let ac = await AccountService.getSelectedAccount()      
      this.accountPermission = ac.data.permission
    },
  },

  mounted(){
    this.init()
  },

    
})
