
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
import Keys from '@/pages/wallet/keysAndPermissions/tabs/components/Keys.vue'
import Accounts from '@/pages/wallet/keysAndPermissions/tabs/components/Accounts.vue'
import Waits from '@/pages/wallet/keysAndPermissions/tabs/components/Waits.vue'
//services
import AccountService from "@/services/AccountServices";
//models
import AccountModel , {Permission , RequiredAuthKey , RequiredAuthAccount , RequiredAuthWait} from "@/models/AccountModel"

export default defineComponent({
  props:{
    permission:Permission,
    index:Number,
    account:AccountModel,
    accountPermission:String
  },

  components:{
    Loading,
    Keys,
    Accounts,
    Waits
  },

  data(){
    const toast = useToast();
    let permissionName:string = ''
    let permissionParent:string = ''
    let permissionTreshold:number = 1

    let keys:RequiredAuthKey[] = []
    let accounts:RequiredAuthAccount[] = []
    let waits:RequiredAuthWait[] = []
    return{
      loading:true,
      toast,
      activeKey:'',
      permissionName,
      permissionParent,
      permissionTreshold,
      keys,
      accounts,
      waits,
      counter:0
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      this.permissionName = this.permission?.permName as string
      this.permissionParent = this.permission?.parent as string
      this.permissionTreshold = this.permission?.requiredAuth?.threshold as number
      this.loading=false;
    },

    setKeys(keys){
      this.keys = keys
      this.counter++
    },
    setAccounts(accounts){
      this.accounts = accounts
      this.counter++
    },
    setWaits(waits){
      this.waits = waits
      this.counter++
    },

    async deletePermission(){
      try {
        await AccountService.advanceDeletePermission(
          this.account?.account_name as string,
          this.accountPermission as string,
          this.permission?.permName as string
        )
      }catch(e){
        this.toast.error(`${e}`)
      }
    },

    async save(){
      let newWaits = this.camelToUnderscore(this.waits)
      this.keys.sort((a, b) => a.key.localeCompare(b.key, 'en-US', {caseFirst: 'lower'}))
      this.accounts.sort((a, b) => a.permission.actor.localeCompare(b.permission.actor, 'en-US', {caseFirst: 'lower'}))
      try {
        await AccountService.advancedChangePermission(
          this.account?.account_name as string,
          this.accountPermission as string,
          this.permissionName as string,
          this.permissionParent as string,
          this.permissionTreshold as number,
          this.keys,
          newWaits,
          this.accounts
        )
        // this.toast.success(`Transaction was successful.`) ;
      }catch(e){
        this.toast.error(`${e}`)
      }
    },


    camelToUnderscore(array) {
      let newArray:any[] = []
      const camelToSnake = (text) => {
        return text.replace( /([A-Z])/g, "_$1" ).toLowerCase();
      }

      for(let item of array){
        let obj:any = {}
        for(let index in item){
          let newIndex = camelToSnake(index)
          obj[newIndex] = item[index]
        }
        newArray.push(obj)
      }      
      return newArray
    }


  },

  mounted(){
    this.init()
  },

    
})
